.loading1 {
  background: #C93E29;
  font-family: 'IBM Plex Sans', sans-serif;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__line {
    position: relative;
    width: 400px;
    height: 2px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 0;
      background: #FCDDBF;
      animation: loading1 1.5s;
      animation-iteration-count: infinite;
      opacity: 0.6;
    }
  }

  &__text {
    color: #FCDDBF;
    font-size: 3rem;
    font-weight: 100;
    text-transform: uppercase;
    margin: 0 0 20px;
    opacity: 0.4;
    text-align: center;
    padding: 0 40px;

  }
  @media only screen and (max-width: 600px) {
    &__line {
      width: 200px;
    }

    &__text {
      font-size: 2.5rem;
      margin: 0 0 40px;
    }
  }
}

@keyframes loading1 {
  0% {
    width: 0%;
  }

  25% {
    left: 0;
  }

  50% {
    width: 100%;
  }

  100% {
    left: 100%;
  }
}