.animation-handler {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  
  &__in, &__out {
    &__play {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  &__in {
    &__play {
      animation-delay: 2s;
      z-index: 0;
      animation: in 2s;
    }
  }

  &__out {
    &__play {
      opacity: 0;
      animation: out 2s;
    }
  }
}

@keyframes in {
  0% {
    opacity: 0;
    filter: blur(16px);
    transform: scale(0, 0);
  }

  100% {
    opacity: 1;
    filter: blur(0px);
    transform: scale(1, 1);
  }
}

@keyframes out {
  0% {
    opacity: 1;
    transform: scale(1, 1);
    filter: blur(0px);
  }

  100% {
    opacity: 0;
    transform: scale(2, 2);
    filter: blur(16px);
  }
}