.full-page-text {
  background: #C93E29;
  font-family: 'IBM Plex Sans', sans-serif;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  
  &__text {
    color: #FCDDBF;
    font-size: 3rem;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 20px;
    opacity: 0.4;
    padding: 0 40px;
  }

  @media only screen and (max-width: 600px) {
    &__text {
      font-size: 2.5rem;
    }
  }
}
